#body {
   height: auto;
   min-height: 400px;
   margin-bottom: 5vh;
   /* margin-top: 5vh; */
   clear: both;
   font-size: 3vh;
}
.GeneralInfoPage .card-header{
    text-align: center;
}
.GeneralInfoPage .infoBox{
    padding-top: 8vh;
}
.react-calendar__month-view__weekdays {
    text-align: center;
}
.react-calendar button{
    /* background-color: white; */
    border-color: white;
    /* all: revert */
}
.available {
    background-color: green;
}
.notavailable {
    background-color: red;
}
a {
    text-decoration: none; /* remove underline */
    color: inherit; /* remove blue */
}
.navbar{
    display: flex;
    height: 8vh;
    width: 100%;
    justify-content: space-evenly;
    background-color: black;
    z-index: 1;
}
/* .navbar{
    display: flex;
    height: 20vh;
    width: 100%;
    justify-content: space-evenly;
    background-color: black;
} */
.navbarcontent {
    border-radius: 1.5vh;
    font-size: 4vh;
    margin: .5vw;
    height: 5vh;
    color: white;
    display: flex;
    justify-content: center;
    width: 10vw;
    border: 1px white solid;
}
.navbar-sticky {
  position: sticky;
  top: 0;
  left: 0;
  animation: moveDown 0.5s ease-in-out;
}
.FH-Contents{
    display: flex;
    flex-wrap: wrap;
}
#Location-Names span{
    font-weight: bold;
}
#FH-Names {
    text-align: center;
}
#Locations{
    width: 30%;
}
#Locations h2{
    margin-left: 4.5%;
}
.FH-Addresses{
    width: 30%;
}
.messageBox{
  width: 300px;
  min-height: 17px;
  max-height: 150px;
  overflow-x: hidden;
  overflow-y: auto;
}
.Alert{
    color: red;
    font-weight: bold;
    font-size: larger;
    -webkit-text-stroke-width: .5px;
    -webkit-text-stroke-color: black;
}
.btn-close {
    height: 2vh;
    width: 2vw; 
}
.footer{
    margin-top: 10px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
}
.scrollable-div {
  background-color: #f1f1f1;
  overflow-y: scroll;
  margin: 20px;
  text-align: justify;
  padding: 20px;
  border-radius: 1vh;
}
.navbar .nav-link {
    color: white !important;
}
.FuneralHome-Card a{
    color: black !important
}
.FuneralHome {
    width: 80%;
	min-height: 300px;
	margin: 0 auto;
	display: -webkit-flex; /* Safari */		
	display: flex; /* Standard syntax */
}
.FuneralHome-Card {
	-webkit-flex: 1;
	-ms-flex: 1; 
	flex: 1;
}
#ModalBox {
    display: block; background: #00000050
}
.SingleFHDirectorList div div {
    width: 100% !important;
}
.SingleFHDirectorList div div div.outerCard{
    display: flex !important;
    flex-wrap: wrap !important;
}
.SingleFHDirectorList div div div div.outerCard .card{
    width: 30vw !important;
}