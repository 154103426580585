Form div {
    display: flex;
    flex-wrap: wrap;
    padding: 1vw;
}
.form-control {
    font-size: 2rem !important;
}
.directorNotified {
    font-size: 1rem !important;
}