.App {
  text-align: center;
}
#body {
  height: auto;
  min-height: 400px;
  margin-bottom: 5vh;
  clear: both;
  font-size: 2vh !important;
  /* padding-top: 40px; */
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.formInput {
  border-radius: 1vh;
}
.App-link {
  color: #61dafb;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.box {
  position: relative;
  width: 125vh;
  margin: 0 auto;
  height: auto;
  max-height: 70vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 4px;
  /* padding: 20px; */
  overflow: auto;
  border: 1px solid #999;
  border-radius: 3vh;
}
.close-icon {
  content: 'x';
  cursor: pointer;
  position: fixed;
  right: calc(15% - 30px);
  top: calc(100vh - 85vh - 33px);
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
}
iframe{
  height: 100vh
}
nav .nav-link.active{
  background-color: black !important
}
.dropdown a{
  text-decoration: none;
  color: white !important
}
div.show.dropdown a{
  color: black !important
}
a {
  /* color: black; */
  text-decoration: none !important;
}

button {
  border-radius: 2vh;
}
#ModalBox {
  display: block; background: #00000050
}
.navbar{
  top: 0;
  background-color: #000;
  display: flex;
  height: 8vh;
  justify-content: space-evenly;
  position: sticky;
  width: 100%;
  z-index: 1;
}
.navbarcontent {
  border-radius: 1.5vh;
  font-size: 4vh;
  margin: .5vw;
  height: 5vh;
  color: white;
  display: flex;
  justify-content: center;
  width: 10vw;
  border: 1px white solid;
}
.navbar-sticky {
position: sticky;
top: 0;
left: 0;
animation: moveDown 0.5s ease-in-out;
}
.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #444 !important;
    --bs-btn-border-color: #444 !important;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: black !important;
    --bs-btn-hover-border-color: black !important;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: black !important;
    --bs-btn-active-border-color: black !important;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: gray !important;
    --bs-btn-disabled-border-color: gray !important;
}
.accordion-body {
  text-align: center !important;
}